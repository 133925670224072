<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{title}} applications <span class="badge badge-primary" v-if="application_status == 'valid-offer' && search.status == 'is_submitted_school'">{{($store.getters.getAcademicApplicationTotalSubmit)}}</span> </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Applications
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field v-model="search.reference_id" outlined dense v-on:keyup.enter="searchApplication"
                    label="Search by application reference no" clearable></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field v-model="search.agent_name" outlined dense v-on:keyup.enter="searchApplication"
                    label="Search by business name" clearable></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field v-model="search.applicant_name" outlined dense v-on:keyup.enter="searchApplication"
                    label="Search by applicant name" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select :items="countries" item-text="title" item-value="id" v-model="search.country_id" outlined clearable
                    @change="onCountryChange(search.country_id, search.application_type)" dense label="Country" v-on:keyup.enter="searchApplication">
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3" v-if="search.application_type=='academic'">
                  <v-select :items="institutions" item-text="title" item-value="id" v-model="search.institution_id"
                    outlined dense v-on:change="onInstitutionAndEducationChange" v-on:keyup.enter="searchApplication" clearable
                    label="Institution"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="search.application_type=='academic'">
                  <v-select :items="education_levels" item-text="title" item-value="id" v-on:keyup.enter="searchApplication" clearable
                    v-model="search.education_level_id" outlined dense
                    v-on:change="onInstitutionAndEducationChange"
                    label="Education level"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="search.application_type=='academic'">
                  <v-select :items="programs" item-text="title" item-value="id" v-model="search.academic_program_id" outlined v-on:keyup.enter="searchApplication"
                            clearable dense label="Program"></v-select>
                </v-col>
                <!--                <v-col cols="12" sm="6" md="3" v-if="search.application_type">-->
                <!--                  <v-select :items="application_statuses" v-model="search.application_status" outlined dense-->
                <!--                            item-value="id" item-text="title"-->
                <!--                            label="Application Status"></v-select>-->
                <!--                </v-col>-->
                <v-col cols="12" sm="6" md="2">
                  <v-select :items="statuses" v-model="search.status" item-value="value" item-text="title" outlined v-on:keyup.enter="searchApplication"
                   clearable dense label="Status"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select :items="applicationStatuses" v-model="search.application_status" item-value="value" item-text="title" outlined v-on:keyup.enter="searchApplication"
                  clearable dense label="Application Status"></v-select>
                </v-col>
                <v-col cols="12" md="3" class="text-right">
                  <v-btn class="btn btn-primary text-white" depressed @click="searchApplication">Search</v-btn>
                  <v-btn class="btn btn-standard  ml-2" depressed @click="resetApplication()">Reset</v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="row">
              <v-col cols="12">
                <div class="table-responsive">
                  <v-skeleton-loader
                      v-if="loading"
                      type="table-thead"
                  >
                  </v-skeleton-loader>

                  <v-skeleton-loader
                      v-if="loading"
                      type="table-row-divider@25"
                  >
                  </v-skeleton-loader>
                  <table class="table" v-if="!loading">
                    <thead>
                    <tr>
                      <th class="text-left">
                        Application detail
                      </th>
                      <th class="text-left">
                        Program & session
                      </th>
                      <th class="text-left">
                        Important dates
                      </th>
                      <th>
                        Payment
                      </th>
                      <th>
                        Discussion category
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                    <template v-if="applications && applications.length>0">
                      <div class="schedule-data" style="width: 100%; display: contents;" v-for="(application,index) in applications" :key="index">
                      <tr>
                        <td>
                          Reference No: <a href="#" @click="openApplicationDetail(application)"> <b>{{
                          application.reference_id }}</b></a> <br />
                          <router-link :to="{name:'student-profile', params:{'user_id':application.applicant_id}}">
                            {{ application.applicant }}
                          </router-link><br />
                          <span class="badge badge-secondary text-uppercase" v-html="application.creator"></span><br>
                          <span class="badge badge-info" v-if="application.branch_name && currentUser && currentUser.access_type == 'head'">{{application.branch_name}}</span>
                          <div v-if="application.agent_id">
                            <v-chip  class="mt-2 text-white" style="cursor:pointer;" @click="viewApplicationChanges(application.id)" small color="red">Recently Updated by Agent</v-chip>
                          </div>
                        </td>

                        <td>
                          <div v-if="application.application_type !='visa'">
                              <div style="width: 100px; float: left; margin-right:10px">
                                  <img  v-if="application.institution_logo && application.institution_logo.real" :src="application.institution_logo && application.institution_logo.real"  style="height: 50px">
                                  <img  v-else :src="`https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=`" style="height: 50px">

                              </div>
                              <div class="mt-3">
                                  <a class="font-weight-bold"> {{ application.institution }}</a>
                              </div>
                              <div class="text-secondary">
                                  <span v-html="application.academic_program_title_location"></span>
                              </div>
                              <div class="text-secondary">
                                  <span class="badge badge-primary text-uppercase">{{
                                  application.academic_session }}</span>
                              </div>

<!--                            <p>-->
<!--                              <b>Country</b>: {{ application.country_name }}-->
<!--                            </p>-->
                          </div>
                          <div v-else>
                            <p>
                              <b>Permit</b> :{{ application.application_permit_title }}
                            </p>
                            <p>
                              <b>Application Type</b> : {{ application.application_type_title }}
                            </p>
                            <p>
                              <b>Country</b> : {{ application.country_name }}
                            </p>
                          </div>
                        </td>

                        <td>
                            <span><b>Application date</b>: {{ application.formatted_created_date }} </span><br/>
                            <span><b>Medical date: </b>:
                              {{
                              application.formatted_medical_date ? application.formatted_medical_date : 'N/A'
                              }}</span><br/>
                            <span><b>Visa lodge: </b>:
                              {{
                              application.formatted_biometric_date ? application.formatted_biometric_date : 'N/A'
                              }}</span><br/>
                            <span><b>Visa decision date: </b>:
                              {{
                              application.formatted_visa_decision_date ? application.formatted_visa_decision_date :
                              'N/A'
                              }}</span>
                        </td>

                        <td >
                          <ul v-if="application.payments.length > 0">
                            <li v-for="(payment, index) in  application.payments" :key="index">
                             <b>Title: </b> {{ payment.title }} <br />
                              <b>Status: </b><span class="badge" :class="payment.status == 'pending' ? 'badge-warning' : 'badge-primary'"> {{ payment.status }} </span>
                            </li>
                          </ul>
                          <span class="text-center" v-else>---</span>
                        </td>

                        <td >
                          <ul v-if="application.discussion_categories.length > 0">
                            <li v-for="(category, index) in  application.discussion_categories" :key="index">{{ category }}</li>
                          </ul>
                          <span class="text-center" v-else>---</span>
                        </td>
                      </tr>
                      <div class="schedule-data-badge" style="width: 100%; display: contents;padding-bottom: 5px">
                          <td colspan="6" style="border-top:none !important;text-align: left;">
                              <div v-if="application.is_opened" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;"> Viewed</div>
                              <div v-if="!application.is_opened" class="badge badge-warning" style="max-width: 100px !important; white-space: pre-wrap;display: inline;"> Not viewed</div>
                              <div v-if="application.is_active" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;"> Valid for offer</div>
                              <div v-if="!application.is_valid_offer" class="badge badge-danger" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Not valid for offer</div>
                              <div v-if="application.is_submitted_school" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Submitted to institution</div>
                              <div v-if="!application.is_submitted_school" class="badge badge-warning" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Not submitted to institution </div>
                              <div v-if="application.is_offer_letter" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Offer letter granted</div>
                              <div v-if="!application.is_offer_letter" class="badge badge-danger" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Offer letter not granted</div>
                              <div v-if="application.is_enrolment_confirmed" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Fee paid & enrolled</div>
                              <div v-if="!application.is_enrolment_confirmed" class="badge badge-warning" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Fee unpaid & not enrolled</div>
                              <div v-if="application.is_ready_for_visa" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Ready for visa</div>
                              <div v-if="!application.is_ready_for_visa" class="badge badge-warning" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Not ready for visa</div>
                              <div v-if="application.application_status_text" class="badge badge-info" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">{{ application.application_status_text }}</div>
                          </td>
                      </div>
                      </div>
                    </template>
                    <template v-else>
                      <tr>
                        <td class="text-center" colspan="6">No data found</td>
                      </tr>
                    </template>
                  </tbody>

                  </table>
                  <b-pagination
                      :disabled="loading"
                      v-if="applications.length > 0"
                      class="pull-right mt-7"
                      @input="getAllApplication"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                </div>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <application-log
      ref="get-application-log"
    ></application-log>
  </v-app>
</template>

<script>
import ApplicationService from "@/services/application/ApplicationService";
import ApplicationLog from "../user/student/profile/components/ApplicationLog";

const applicationService = new ApplicationService();

export default {
  name: "Index",
  components: {
    ApplicationLog
  },
  data() {
    return {
      loading: false,
      title: '',
      total: null,
      perPage: null,
      page: 1,
      applications: [],
      institutions: [],
      programs: [],
      application_statuses: [],
      application_types: [
        { value: 'visa', title: "VISA" },
        { value: 'academic', title: 'Academic' }
      ],
      statuses: [
        { title: 'Submitted to institution', value: 'is_submitted_school' },
        { title: 'Not submitted to institution', value: 'is_not_submitted_school' },
      ],
      applicationStatuses: [
        { title: 'Visa not applied', value: 'waiting' },
        { title: 'Visa applied & waiting', value: 'visa_applied' },
        { title: 'Visa granted', value: 'granted' },
        { title: 'Visa refused', value: 'rejected' },
      ],
      search: {
        reference_id: '',
        agent_name: '',
        applicant_name: '',
        institution_id: '',
        education_level_id: '',
        is_opened: '',
        is_draft: '',
        is_valid_offer: '',
        is_complete: '',
        is_deferred: '',
        is_refund: '',
        is_archived: '',
        application_status: '',
      },
    }
  },
  computed: {
    application_type() {
      return this.$route.params.type;
    },
    application_status() {
      return this.$route.params.status;
    },
    referenceId() {
      return this.$route.query.reference_id;
    }
  },
  mounted() {
    if(this.referenceId) {
      this.search.reference_id = this.referenceId;
    }
    this.search.application_status = ''
    if (this.application_status == 'draft') {
      this.title = 'Draft'
      this.filter('', 1, 0, 0, 0, 0, 0);
    } else if (this.application_status == 'submitted') {
      this.title = 'Submitted'
      this.search.application_status = 'waiting';
      this.search.is_valid_offer = 0;
      this.search.status = 'is_not_submitted_school';
      this.filter('', 0, 0, 0, 0, 0, 0);
    } else if (this.application_status == 'valid-offer') {
      this.title = 'Validated for offer'
      this.filter('', 0, 0, 0, 0, 0, 0);
      this.search.is_valid_offer = 1;
      this.search.status = 'is_not_submitted_school';
    } else if (this.application_status == 'offer') {
      this.title = 'Offered'
      this.filter('', 0, 1, 0, 0, 0, 0);
      this.search.is_ready_for_visa = 0;
    } else if (this.application_status == 'deferred') {
      this.title = 'Deferred'
      this.filter('', 0, '', 0, 1, 0, 0);
    } else if (this.application_status == 'refund') {
      this.title = 'Refund'
      this.filter('', 0, 0, 0, 0, 1, 0);
    } else if (this.application_status == 'archived') {
      this.title = 'Archived'
      this.filter('', '', '', '', '', '', 1);
    } else if (this.application_status == 'visa-ready') {
      this.title = 'Visa ready'
      this.filter('', 0, 1, 0, 0, 0, 0);
      this.search.is_ready_for_visa = 1;
      this.search.application_status = 'visa_applied';
    } else if (this.application_status == 'visa-granted') {
      this.title = 'Visa granted'
      this.filter('', 0, 1, 0, 0, 0, 0);
      this.search.is_ready_for_visa = 1;
      this.search.application_status = 'granted';
    } else {
      this.filter('', '', '', '', '', '');
    }
    this.getCountries();
    this.getAllApplication();
    this.search.application_type = this.application_type;
    this.getEducationLevel();
  },
  methods: {
    resetApplication() {
      this.search = {
        application_type: this.application_type
      }
      if (this.application_status == 'submitted') {
        this.search.application_status = 'waiting';
        this.search.is_valid_offer = 0;
        this.search.status = 'is_not_submitted_school';
      }
      if (this.application_status == 'valid-offer') {
        this.search.is_valid_offer = 1;
        this.search.status = 'is_not_submitted_school';
      }
      this.getAllApplication();
    },

    searchApplication() {
      this.page = 1;
      this.getAllApplication();
    },

    filter(is_opened, is_draft, is_offer_letter, is_complete, is_deferred, is_refund, is_archived) {
      this.search.is_opened = is_opened;
      this.search.is_draft = is_draft;
      this.search.is_offer_letter = is_offer_letter;
      this.search.is_complete = is_complete;
      this.search.is_deferred = is_deferred;
      this.search.is_refund = is_refund;
      this.search.is_archived = is_archived;
    },

    onCountryChange(countryId, applicationType = null) {
      this.getInstitution(countryId)
      this.getVisaApplicationStatus(countryId, applicationType)
    },

    onInstitutionAndEducationChange() {
      if(this.search.institution_id && this.search.education_level_id) {
        this.getProgramByInstitution(this.search.institution_id, this.search.education_level_id)
      }
    },

    getAllApplication() {
      this.search.application_type = this.application_type;
      this.loading = true;
      applicationService.search(this.search, this.page).then(response => {
        this.applications = response.data.applications;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },

    openApplicationDetail(application) {
      if(!application.is_opened) {
        applicationService.markAsViewed(application.id).then(() => {}).catch(() => {});
      }
      this.$router.push({ name: 'application-detail', params: { id: application.id } })
    },

    viewApplicationChanges(applicationId){
      applicationService
      .getChangeLog(applicationId)
      .then((res) => {
        this.applicationLog = res.data.data;
        this.$refs['get-application-log'].openDialog(this.applicationLog);
      })
      .catch(err => {
        this.$snotify.error('Unfortunately we could not show any changes.');
      })
    },
  }
}
</script>
